module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KT72CT","gtmAuth":"NEGSlcU0xGo6joAEAPMGaw","gtmPreview":"env-1024","selfHostedOrigin":"https://wtm.worthy.com/www.googletagmanager.com","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-rollbar/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"a04b13bee35d4fe2b1f7d5a29e37a1da","captureUncaught":true,"captureUnhandledRejections":true,"payload":{"environment":"demo"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 540px)","sm":"(max-width: 770px)","md":"(max-width: 950px)","l":"(max-width: 1290px)","xl":"(max-width: 1440px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://welcome-demo.worthy.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
